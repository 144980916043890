import React, {useState, useEffect} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import axios from 'axios'
import {errMsg, successMsg, showMsgInSeconds} from '../../utils/msg/Msg'
import {dispatchLogin} from '../../../../redux/actions/authAction'
import {useDispatch} from 'react-redux'

import './login.scss'

const proxy = ''

const initialState = {
    username: '',
    password: ''
}

const msgDuration = 2

export default function Login() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [user, setUser] = useState(initialState)
    const {username, password} = user

    useEffect(() => {
        const test = async() => {
            const res = await axios.get(`${proxy}/test`)
            console.log(res)
        }
        test()
    }, [])
    

    const handleChange = (e) => {
        const {name, value} = e.target
        setUser({...user, [name]:value})
    }

    const handleSubmit = async(e) => {
        e.preventDefault()
        try {
            const res = await axios.post(`${proxy}/user/login`, {username, password})

            localStorage.setItem('firstlogin', true)
            dispatch(dispatchLogin())
            navigate('/profile/general')

        } catch (err) {
            err.response.data.msg &&
            alert(err.response.data.msg)           
        }
    }
    return (
        <div id="login" className='pre-auth-wrap'>
            <header>Admin</header>
            <div className="pre-auth-body">
                <form onSubmit={handleSubmit}>
                    <h1 className="form-title">User Login</h1>
                    <div className="input-wrap">
                        <label htmlFor="username">Username</label>
                        <input type="text" id="username" name="username" placeholder="Enter your username" onChange={handleChange}/>
                    </div>
                    <div className="input-wrap">
                        <label htmlFor="password">Password</label>
                        <input type="password" id="password" name="password" placeholder="Enter your password"  onChange={handleChange}/>
                    </div>
                    <button type="submit">Login</button>
                    <Link to="/forgot">Forgot your password?</Link>
                    <p>New user? <Link to="/register">Register here</Link></p>
                </form>
            </div>
        </div>
    )
}
