import React, {useState, useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {dispatchLogin, fetchUser, dispatchGetUser} from '../../../../../redux/actions/authAction'
import axios from 'axios'
import { errMsg, successMsg, showMsgInSeconds} from '../../../utils/msg/Msg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import fx from '../../../utils/fx'

import Profile from '../Profile'
import Modal from '../../../utils/modal/Modal'
import Loading from '../../../utils/loading/Loading'
import Loader from '../../../utils/loader/Loader'

import './games.scss'

const proxy = ''

const msgDuration = 3

const initMsg = {err: '', success: ''}

const initData = {
    newGameInfo: {name: '', subdomain: '', subtitle: '', claim: '', ingameClaim: '', explainer: ''},
    newGameVocab: {wordsList: [], dictionary: ''},
    newGameColors: {standardLetterBg: '#000000', goodLetterBg: '#000000', misplacedLetterBg: '#000000', letterCaseOutline: '#000000', claimColor: '#000000', pageBg: '#000000'}
}

/* Game parameters */
const gameParameters = {
    minWordLength: 5,
    maxWordLength: 9,
    wordsListMinimumLength: 31
}

export default function Games() {
    const dispatch = useDispatch()
    const token = useSelector(state => state.token)
    const [data, setData] = useState(initData)
    const [games, setGames] = useState([])

    const [msg, setMsg] = useState({games: initMsg, game: initMsg, modal: initMsg})

    const [modalOpened, setModalOpened] = useState(false)

    const [elementID, setElementID] = useState(null)

    //Loading
    const [loadingPage, setLoadingPage] = useState(true)
    const [loadingGames, setLoadingGames] = useState(true)

    //CB
    const [cbUser, setCbUser] = useState(false)
    const [cbGames, setCbGames] = useState(false)
    const [cbGame, setCbGame] = useState(false)

    let element = null
    if(elementID) element = games?.find(el => el._id === elementID)

    useEffect(() => {
        if(token){
            const getUser = () => {
                dispatch(dispatchLogin())
                return fetchUser(token).then(res => {
                    dispatch(dispatchGetUser(res))
                })
            }
            getUser()
        }
    }, [token, dispatch, cbUser])

    useEffect(() => {
        setLoadingGames(true)
        const getGames = async() => {
            try {
                const res = await axios.get(`${proxy}/data/games/get/all`, {
                    headers: { Authorization: token }
                })
                console.log(res.data)
                setGames(res.data.games)
                setLoadingPage(false)
                setLoadingGames(false)
            } catch (err) {
                err.response.data.msg &&
                showMsgInSeconds(setMsg, msg, 'games', {err: err.response.data.msg, success: ''}, msgDuration)
                setLoadingPage(false)
                setLoadingGames(false)
            }
        }
        if(token) getGames()
    }, [token, cbGames])

    const handleChangeData = e => {
        const {id, value, checked, files} = e.target
        const file = files ? files[0] : null
        const [input, type, prop] = id.split(' ')

        let existingFiles = []
        if(input === "file"){
            if(!file) {
                return showMsgInSeconds(setMsg, msg, 'modal', {err: "No image file selected", success: ''}, msgDuration)
            }
            if(data.report.images.filter(el => el.name === file.name).length > 0)
                return showMsgInSeconds(setMsg, msg, 'modal', {err: "Coincidencia de nombre de archivo", success:''}, msgDuration)
            if(file.type !== 'image/jpeg' && file.type !== 'image/png')
                return showMsgInSeconds(setMsg, msg, 'modal', {err: "Incorrect format", success:''}, msgDuration)
            if(file.size > 1024 * 1024)
                return showMsgInSeconds(setMsg, msg, 'modal', {err: "File is too large", success: ''}, msgDuration)

            existingFiles = data.images
        }
        
        const fvalue = input === "checkbox" ? checked : input === "file" ? [...existingFiles, file] : value
        setData({...data, [type]: {...data[type], [prop]: fvalue}})
    }

    const clearInputs = e => {
        e.preventDefault()
        setData({...data, newProduct: initData.newProduct, addingNewSubcategory: false})
        document.getElementsByName('canReset').forEach(el => el.value = '')
    }

    const closeModal = () => {
        setModalOpened(false)
    }

    const selectElement = (id) => {
        setElementID(id)
    }

    const addNewGame = async() => {
        const {newGameInfo, newGameVocab, newGameColors} = data
        if(Object.values(newGameInfo).some(el => el === ''))
            return showMsgInSeconds(setMsg, msg, 'modal', {err: 'Complete all information', success: ''}, msgDuration)
        if(Object.values(newGameColors).some(el => el === ''))
            return showMsgInSeconds(setMsg, msg, 'modal', {err: 'Complete all information', success: ''}, msgDuration)
        try {
            const res = await axios.post(`${proxy}/data/games/add/one`, {newGameInfo, newGameVocab, newGameColors},{
                headers: { Authorization: token }
            })
            console.log(res.data)
            setCbGames(!cbGames)
            closeModal()
        } catch (err) {
            err.response.data.msg &&
            showMsgInSeconds(setMsg, msg, 'modal', {err: err.response.data.msg, success: ''}, msgDuration)
        
        }
    }

    const handleUpdateField = async(e) => {
        e.preventDefault()
        if(!element.published) return alert('Game must not be published before updating any field')
        const {id, value} = e.target
        const [input, prop] = id.split(' ')

        if(value === element[prop]) return
        if(!value) return document.getElementById(id).value = element[prop]
        const confirmContinue = window.confirm(`Update field with '${value}' ?`)
        if(!confirmContinue) return document.getElementById(id).value = element[prop]

        try {
            const res = await axios.put(`${proxy}/data/games/update/by-id/${elementID}`, {prop, value},{
                headers: { Authorization: token }
            })
            console.log(res.data)
            setCbGames(!cbGames)
            setCbGame(!cbGame)
        } catch (err) {
            err.response.data.msg &&
            alert(err.response.data.msg)
        }
    }

    const handleUpdateWordsList = async(e) => {
        e.preventDefault()
        if(!element.published) return alert('Game must not be published before updating any field')
        const {id, value} = e.target
        const [input, prop] = id.split(' ')
        const words = value ? value.split('\n') : []

        if(value === element[prop].join('\n')) return
        if(words.some(el => el === '')) return alert('New lines cannot be empty')
        const incorrectLength = words.find(el => el.trim().length < gameParameters.minWordLength || el.trim().length > gameParameters.maxWordLength)
        if(incorrectLength) return alert(`New words must have a length between ${gameParameters.minWordLength} and ${gameParameters.maxWordLength}. Found: ${incorrectLength}`)
        
        const includesNonLetter = words.find(el => !el.trim().toUpperCase().match(/^[a-zA-Z]+$/))
        if(includesNonLetter) return alert(`Only letters are admited for words. Found: ${includesNonLetter}`)
        
        if(words.length < gameParameters.wordsListMinimumLength) return alert(`The minimum length of the words list is ${gameParameters.wordsListMinimumLength}. Current length: ${words.length}`)

        const toFindDuplicates = array => array.map((el, idx) => {return {word: el.toUpperCase(), idx}}).filter((item, index, arr) => arr.map(el => el.word).indexOf(item.word) !== index)
        const duplicatesFound = toFindDuplicates(words)
        if(duplicatesFound.length > 0) return alert(`Duplicated words found: \n ${duplicatesFound.map(el => ` ${el.word} (line ${el.idx+1})`)}`)

        const confirmContinue = window.confirm(`New words count: ${words.length}. Update words list ?`)
        if(!confirmContinue) return document.getElementById(id).value = element[prop].join('\n')
        
        const loaderID = 'loader-'+String(e.target.parentElement.id.split('-')[2])
        document.getElementById(loaderID).style.display = 'block'
        try {
            const res = await axios.put(`${proxy}/data/games/update/by-id/${elementID}`, {prop, value: words.map(el => el.trim().toUpperCase())},{
                headers: { Authorization: token }
            })
            console.log(res.data)
            setCbGames(!cbGames)
            setCbGame(!cbGame)
            document.getElementById(loaderID).style.display = 'none'
        } catch (err) {
            err.response.data.msg &&
            alert(err.response.data.msg)
            document.getElementById(loaderID).style.display = 'none'
        }
    }

    const handleUpdateImage = async(e) => {
        //e.preventDefault()
        if(!element.published) return alert('Game must not be published before updating any field')
        const file = e.target.files[0]

        if(!file) return alert("No file selected")
        
        if(file.type !== 'image/jpeg' && file.type !== 'image/png')
            return alert("Incorrect format")

        if(file.size > 1024 * 1024)
            return alert("File is too large")

        const loaderID = 'loader-'+String(e.target.parentElement.id.split('-')[2])
        document.getElementById(loaderID).style.display = 'block'
        try {
            let formData = new FormData()
            formData.append('file', file)
            formData.append('prop', e.target.id.split('-')[1])
            const res = await axios.post(`${proxy}/file/upload_image/by-id/${elementID}`, formData, {
                headers: {
                    Authorization: token,
                    'content-type': 'multipart/form-data'
                }
            })
            console.log(res.data)
            setCbGames(!cbGames)
            document.getElementById(loaderID).style.display = 'none'
        } catch (err) {
            err.response.data.msg &&
            alert(err.response.data.msg)
            document.getElementById(loaderID).style.display = 'none'
        }
    }

    const handleUpdateSound = async(e) => {
        //e.preventDefault()
        if(!element.published) return alert('Game must not be published before updating any field')
        const file = e.target.files[0]

        if(!file) return alert("No file selected")
        
        if(file.type !== 'audio/mpeg' && file.type !== 'audio/mp3')
            return alert("Incorrect format")

        if(file.size > 1024 * 1024)
            return alert("File is too large")

        const loaderID = 'loader-'+String(e.target.parentElement.id.split('-')[2])
        document.getElementById(loaderID).style.display = 'block'
        try {
            let formData = new FormData()
            formData.append('file', file)
            formData.append('prop', e.target.id.split('-')[1])
            const res = await axios.post(`${proxy}/file/upload_audio/by-id/${elementID}`, formData, {
                headers: {
                    Authorization: token,
                    'content-type': 'multipart/form-data'
                }
            })
            console.log(res.data)
            setCbGames(!cbGames)
            document.getElementById(loaderID).style.display = 'none'
        } catch (err) {
            err.response.data.msg &&
            alert(err.response.data.msg)
            document.getElementById(loaderID).style.display = 'none'
        }
    }

    const missingData = (section) => {
        if(!element) return
        switch (section) {
            case 'info':
                const {name, subdomain, subtitle, claim, ingameClaim, explainer} = element
                if(!name || !subdomain || !subtitle || !claim || !ingameClaim || !explainer) return true
                break;
            case 'logo':
                const {logo} = element
                if(!logo) return true
                break;
            case 'vocab':
                const {dictionary, wordsList} = element
                if(!dictionary || dictionary === 'none' || (dictionary === 'wl' && wordsList.length === 0)) return true
                break;
            case 'colors':
                const {standardLetterBg, goodLetterBg, misplacedLetterBg, claimColor, letterCaseOutline, pageBg} = element
                if(!standardLetterBg || !goodLetterBg || !misplacedLetterBg || !claimColor || !letterCaseOutline || !pageBg) return true
                break;
            case 'pattern':
                const {pageBgPattern} = element
                if(!pageBgPattern) return true
                break;
            case 'sounds':
                const {goodLetterSound, wrongLetterSound, misplacedLetterSound, winSound, lossSound} = element
                if(!goodLetterSound || !wrongLetterSound || !misplacedLetterSound || !winSound || !lossSound) return true
                break;
            default:
                break;
        }
        return false
    }

    const handleUpdatePublished = async(e) => {
        //e.preventDefault()
        const {value} = e.target
        console.log(value)
        if(missingData('info') || missingData('logo') || missingData('vocab') || missingData('colors') || missingData('sounds')) {
            e.target.value = element.published
            return alert('A published game must have ALL fields completed first')
        }
        try {
            const res = await axios.put(`${proxy}/data/games/publish/by-id/${elementID}`, {prop: 'published', value},{
                headers: { Authorization: token }
            })
            console.log(res.data)
            setCbGames(!cbGames)
            setCbGame(!cbGame)
        } catch (err) {
            console.log(err.response.data.msg)
            err.response.data.msg &&
            alert(err.response.data.msg)
        }
    }

    const handleDeleteImage = async(e) => {
        e.preventDefault()
        const [action, prop] = e.target.id.split(' ')
        const confirmContinue = window.confirm(
            prop !== 'pageBgPattern' ? `Removing this element will unpublish the game. Continue?`
            : `Remove this element?`)
        if(!confirmContinue) return
        
        const loaderID = 'loader-'+String(e.target.parentElement.children[2].id.split('-')[2])
        document.getElementById(loaderID).style.display = 'block'
        try {
            const res = await axios.delete(`${proxy}/data/games/delete-image/by-id/${elementID}?prop=${prop}`,{
                headers: { Authorization: token }
            })
            document.getElementById(loaderID).style.display = 'none'
            console.log(res.data)
            setElementID(null)
            setCbGames(!cbGames)
        } catch (err) {
            console.log(err)
            err.response?.data?.msg && alert(err.response?.data?.msg)
            document.getElementById(loaderID).style.display = 'none'
        }
    }
    
    const removeElement = async(e) => {
        e.preventDefault()
        const confirmContinue = window.confirm(`Delete game ${element.name.toUpperCase()} ?`)
        if(!confirmContinue) return
        try {
            const res = await axios.delete(`${proxy}/data/games/delete/by-id/${elementID}`,{
                headers: { Authorization: token }
            })
            console.log(res.data)
            setElementID(null)
            setCbGames(!cbGames)
        } catch (err) {
            err.response.data.msg &&
            showMsgInSeconds(setMsg, msg, 'games', {err: err.response.data.msg, success: ''}, msgDuration)
        }
    }

    return (
        <Profile>
            <Modal open={modalOpened} close={closeModal}>
                <div id="modal-create-game"className="modal-container">                  
                    <p className="modal-title">Create a New Game</p>
                    <section className='flexcol'>
                        <div className='section-header'>
                            <p className='modal-section-title'>Game Information</p>
                            <p className='modal-section-subtitle'>Complete the following information about the new game</p>
                        </div><div className="input-wrap flexrow align-ce">
                            <i className="fas fa-caret-right"> </i>
                            <div className='input-wrap-input-element'>
                                <input className='input-element' type="text" placeholder="Name" defaultValue={data.newGameInfo.name} name='canReset' id="text newGameInfo name" onChange={handleChangeData}/>
                            </div>
                        </div>
                        
                        <div className="input-wrap flexrow align-ce">
                            <i className="fas fa-caret-right"> </i>
                            <div className='input-wrap-input-element'>
                                <input className='input-element' type="text" placeholder="Subdomain" defaultValue={data.newGameInfo.subdomain} name='canReset' id="text newGameInfo subdomain" style={{textTransform: 'lowercase'}} onChange={handleChangeData}/>
                            </div>
                        </div>
                        
                        <div className="input-wrap flexrow align-ce">
                            <i className="fas fa-caret-right"> </i>
                            <div className='input-wrap-input-element'>
                                <input className='input-element' type="text" placeholder="Subtitle" defaultValue={data.newGameInfo.subtitle} name='canReset' id="text newGameInfo subtitle" onChange={handleChangeData}/>
                            </div>
                        </div>
                        
                        <div className="input-wrap flexrow align-ce">
                            <i className="fas fa-caret-right"> </i>
                            <div className='input-wrap-input-element'>
                                <input className='input-element' type="text" placeholder="Claim" defaultValue={data.newGameInfo.claim} name='canReset' id="text newGameInfo claim" onChange={handleChangeData}/>
                            </div>
                        </div>

                        
                        <div className="input-wrap flexrow align-ce">
                            <i className="fas fa-caret-right"> </i>
                            <div className='input-wrap-input-element'>
                                <input className='input-element' type="text" placeholder="In-game Claim" defaultValue={data.newGameInfo.ingameClaim} name='canReset' id="text newGameInfo ingameClaim" onChange={handleChangeData}/>
                            </div>
                        </div>
                        
                        <div className="input-wrap flexrow align-ce">
                            <i className="fas fa-caret-right"> </i>
                            <div className='input-wrap-input-element'>
                                <textarea className='input-element' placeholder="Explainer" rows='5' defaultValue={data.newGameInfo.explainer} name='canReset' id="text newGameInfo explainer" onChange={handleChangeData}></textarea>
                            </div>
                        </div>
                    </section>
                    {/*
                    <section className='flexcol'>
                        <div className='section-header'>
                            <p className='modal-section-title'>Game Vocabulary</p>
                            <p className='modal-section-subtitle'>Create a words list for the new game and select what going to be the dictionary source when published</p>
                            <div className="input-wrap flexrow align-ce">
                                <i className="fas fa-caret-right"> </i>
                                <div className="input-wrap-input-element">
                                    <textarea className='input-element' type='text' rows='10' placeholder='Words List (one per line)' defaultValue={data.newGameVocab.wordsList.join('\r\n')} onKeyDown={handleChangeWordsList}></textarea>
                                </div>
                            </div>
                            
                            <div className="input-wrap flexrow align-ce">
                                <i className="fas fa-caret-right"> </i>
                                <div className="input-wrap-input-element">
                                    <select className='input-element' defaultValue='' name='canReset' id='text newGameVocab dictionary' onChange={handleChangeData}>
                                        <option value=''>-- Reference dictionary --</option>
                                        <option value='wl'>Words List</option>
                                        <option value='ext_fr_dict'>External French Dictionary</option>
                                        <option value='ext_eng_dict'>External English Dictionary</option>
                                        <option value='hos_fr_dict'>Hosted French Dictionary</option>
                                        <option value='hos_eng_dict'>Hosted English Dictionary</option>
                                        <option value='none'>None</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                </section>*/}
                    <section className='flexcol'>
                        <div className='section-header'>
                            <p className='modal-section-title'>Game Colors</p>
                            <p className='modal-section-subtitle'>Select the right colors for the new game!</p>
                        </div>
                        <div className="input-wrap flexrow align-ce">
                            <label className="wlabel">Standard Letter Background</label>
                            <i className="fas fa-caret-right"> </i>
                            <div className='input-wrap-input-element'>
                                <input className='input-element' type="color" placeholder="Standard letter background" defaultValue={data.newGameColors.standardLetterBg} name='canReset' id="color newGameColors standardLetterBg" onChange={handleChangeData}/>
                            </div>
                        </div>
                        <div className="input-wrap flexrow align-ce">
                            <label className="wlabel">Good Letter Background</label>
                            <i className="fas fa-caret-right"> </i>
                            <div className='input-wrap-input-element'>
                                <input className='input-element' type="color" placeholder="Good letter background" defaultValue={data.newGameColors.goodLetterBg} name='canReset' id="color newGameColors goodLetterBg" onChange={handleChangeData}/>
                            </div>
                        </div>
                        <div className="input-wrap flexrow align-ce">
                            <label className="wlabel">Misplaced Letter Background</label>
                            <i className="fas fa-caret-right"> </i>
                            <div className='input-wrap-input-element'>
                                <input className='input-element' type="color" placeholder="Enter the misplaced letter background" defaultValue={data.newGameColors.misplacedLetterBg} name='canReset' id="color newGameColors misplacedLetterBg" onChange={handleChangeData}/>
                            </div>
                        </div>
                        <div className="input-wrap flexrow align-ce">
                            <label className="wlabel">Explainer and Claim Color</label>
                            <i className="fas fa-caret-right"> </i>
                            <div className='input-wrap-input-element'>
                                <input className='input-element' type="color" placeholder="Enter the explainer and claim color color" defaultValue={data.newGameColors.claimColor} name='canReset' id="color newGameColors claimColor" onChange={handleChangeData}/>
                            </div>
                        </div>
                        <div className="input-wrap flexrow align-ce">
                            <label className="wlabel">Page Background Color</label>
                            <i className="fas fa-caret-right"> </i>
                            <div className='input-wrap-input-element'>
                                <input className='input-element' type="color" placeholder="Enter the page background color" defaultValue={data.newGameColors.pageBg} name='canReset' id="text newGameColors pageBg" onChange={handleChangeData}/>
                            </div>
                        </div>
                        <div className="input-wrap flexrow align-ce">
                            <label className="wlabel">Letter Case Outline</label>
                            <i className="fas fa-caret-right"> </i>
                            <div className='input-wrap-input-element'>
                                <input className='input-element' type="color" placeholder="Letter case outline" defaultValue={data.newGameColors.letterCaseOutline} name='canReset' id="text newGameColors letterCaseOutline" onChange={handleChangeData}/>
                            </div>
                        </div>
                    </section>

                    <div className='button-container'>   
                        <button onClick={addNewGame}>Create Game</button>
                        <button onClick={clearInputs}>Reset fields</button>  
                    </div>
                    <div className="modal-msg">
                        {msg.modal.err && errMsg(msg.modal.err)}
                        {msg.modal.success && successMsg(msg.modal.success)}
                    </div>
                </div>
            </Modal>
            {loadingPage ? <div style={{fontSize: '100px'}}><Loader/></div>
            :
            <div id='games' className='auth-wrap'>
                <section>
                    <div className='title-container'>
                        <div className='flexrow align-ce'><p className='title'>List of games</p><button className='add-game-button' onClick={()=>setModalOpened(true)}><FontAwesomeIcon className='clickable' icon="fa-solid fa-circle-plus"/> Add New</button></div>
                        <div className="msg">
                            {msg.games.err && errMsg(msg.games.err)}
                            {msg.games.success && successMsg(msg.games.success)}
                        </div>
                    </div>
                    
                    <div className='table-container'>
                        <table>
                            <thead>
                                <tr>
                                    <th>Published</th>
                                    <th>Name</th>
                                    <th>Subdomain</th>
                                    <th>Created</th>
                                    <th>Modified</th>
                                </tr>
                            </thead>
                            {!loadingGames &&
                            <tbody>
                                {games?.length === 0? 
                                <tr>
                                    <td colSpan={100}>
                                        <p>No games found</p>
                                    </td>
                                </tr>
                                :games?.map((el) => {
                                    return (
                                        <tr key={el._id} className={`table-row-data pointer ${el.published ? 'is-available' : 'not-available'}`} onClick={()=> selectElement(el._id)}>
                                            <td>{el.published.toUpperCase()}</td>
                                            <td>{el.name}</td>
                                            <td>{el.subdomain}</td>
                                            <td>{new Date(el.createdAt).toLocaleDateString('en-US')}</td>
                                            <td>{new Date(el.updatedAt).toLocaleDateString('en-US')}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>}
                        </table>
                        {loadingGames && 
                        <div className='loader-games'>
                            <Loader />
                        </div>}
                    </div>
                </section>

                {elementID &&
                <section id='element' className='element-page'>
                    <div className='icons-container'>
                        <FontAwesomeIcon icon="fa-solid fa-arrow-up-from-bracket" title='Close' className='close-element-icon clickable-icon' onClick={()=>setElementID(null)}/>
                    </div>

                    <div className='subsection'>
                        <p className='subsection-title'>Game Info {missingData('info') ? <span className='missing-data'>Missing data!</span> : ''}</p>
                        <p className='subsection-subtitle'>Every field can be updated, just type in a new value and confirm</p>
                        <div className='subsection-content margin-h-auto'>
                            <div className='content-card'>
                                <p className='content-card-label'>Name</p>
                                <div className='update-field-container'>
                                    <input className='update-field' id='text name' defaultValue={element.name} onBlur={handleUpdateField}/>
                                </div>
                            </div>
                            <div className='content-card'>
                                <p className='content-card-label'>Subdomain</p>
                                <div className='update-field-container'>
                                    <input className='update-field' id='text subdomain' defaultValue={element.subdomain}  style={{textTransform: 'lowercase'}} onBlur={handleUpdateField}/>
                                </div>
                            </div>
                            <div className='content-card'>
                                <p className='content-card-label'>Subtitle</p>
                                <div className='update-field-container'>
                                    <input className='update-field' id='text subtitle' defaultValue={element.subtitle} onBlur={handleUpdateField}/>
                                </div>
                            </div>
                            <div className='content-card'>
                                <p className='content-card-label'>Claim</p>
                                <div className='update-field-container'>
                                    <input className='update-field' id='text claim' defaultValue={element.claim} onBlur={handleUpdateField}/>
                                </div>
                            </div>
                            <div className='content-card'>
                                <p className='content-card-label'>In-Game Claim</p>
                                <div className='update-field-container'>
                                    <input className='update-field' id='text ingameClaim' defaultValue={element.ingameClaim} onBlur={handleUpdateField}/>
                                </div>
                            </div>
                            <div className='content-card'>
                                <p className='content-card-label'>Explainer</p>
                                <div className='update-field-container'>
                                    <textarea rows='5' cols='10' className='update-field' id='text explainer' defaultValue={element.explainer} onBlur={handleUpdateField}></textarea>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='subsection'>
                        <p className='subsection-title'>Game Logo {missingData('logo') ? <span className='missing-data'>Missing data!</span> : ''}</p>
                        <p className='subsection-subtitle'>Click on the image box and update the game logo</p>
                        <div className='subsection-content'>
                            <div className='content-card'>
                                <p className='content-card-label'>Logo</p>
                                {element.logo && <p className='remove' id='delete logo' onClick={handleDeleteImage}>REMOVE</p>}
                                <div id='with-loader-5' className='update-field-container'>
                                    <input type='file' className='hidden-file-input' id='file-logo' onChange={handleUpdateImage}/>
                                    {element.logo ?
                                    <img className='update-field image-field' src={element.logo ? element.logo : ''} alt='game logo update field' onClick={()=>document.getElementById('file-logo').click()}/>
                                    :
                                    <p className='update-field image-field' onClick={()=>document.getElementById('file-logo').click()}>&#9656; Select new logo</p>}
                                    <div id='loader-5' className='loader-container'><Loader /></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className='subsection'>
                        <p className='subsection-title'>Vocabulary {missingData('vocab') ? <span className='missing-data'>Missing data!</span> : ''}</p>
                        <p className='subsection-subtitle'>Define the game dictionary</p>
                        <div className='subsection-content'>
                            <div className='content-card'>
                                <p className='content-card-label'>Reference Dictionary</p>
                                <div className='update-field-container'>
                                    <select className='update-field' id='text dictionary' defaultValue={element.dictionary} onChange={handleUpdateField}>
                                        <option value=''>-- Select an option --</option>
                                        <option value='wl'>Words List</option>
                                        {/*<option value='ext_fr_dict'>External French Dictionary</option>
                                        <option value='ext_eng_dict'>External English Dictionary</option>*/}
                                        <option value='hos_fr_dict'>Hosted French Dictionary</option>
                                        <option value='hos_eng_dict'>Hosted English Dictionary</option>
                                        <option value='none'>None</option>
                                    </select></div>
                            </div>
                        </div>
                        <div className='subsection-content'>
                            <div className='content-card'>
                                <p className='content-card-label'>Words List</p>
                                <div id='with-loader-7' className='update-field-container'>
                                    <textarea rows='5' cols='10' className='update-field' id='text wordsList' defaultValue={element.wordsList.join('\n')} placeholder='Type in words (one per line)' onBlur={handleUpdateWordsList}></textarea>
                                    <div id='loader-7' className='loader-container'><Loader /></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='subsection'>
                        <p className='subsection-title'>Colors {missingData('colors') ? <span className='missing-data'>Missing data!</span> : ''}</p>
                        <p className='subsection-subtitle'>Update the game colors</p>
                        <div className='subsection-content'>
                            <div className='content-card'>
                                <p className='content-card-label'>Standard Letter Background Color</p>
                                <div className='update-field-container'>
                                    <input type='color' className='update-field' id='text standardLetterBg' defaultValue={element.standardLetterBg} onBlur={handleUpdateField}/>
                                </div>
                            </div>
                            <div className='content-card'>
                                <p className='content-card-label'>Good Letter Background Color</p>
                                <div className='update-field-container'>
                                    <input type='color' className='update-field' id='text goodLetterBg' defaultValue={element.goodLetterBg} onBlur={handleUpdateField}/>
                                </div>
                            </div>
                            <div className='content-card'>
                                <p className='content-card-label'>Misplaced Letter Background Color</p>
                                <div className='update-field-container'>
                                    <input type='color' className='update-field' id='text misplacedLetterBg' defaultValue={element.misplacedLetterBg} onBlur={handleUpdateField}/>
                                </div>
                            </div>
                            <div className='content-card'>
                                <p className='content-card-label'>Explainer and Claim Color</p>
                                <div className='update-field-container'>
                                    <input type='color' className='update-field' id='text claimColor' defaultValue={element.claimColor} onBlur={handleUpdateField}/>
                                </div>
                            </div>
                            <div className='content-card'>
                                <p className='content-card-label'>Letter Case Outline</p>
                                <div className='update-field-container'>
                                    <input type='color' className='update-field' id='text letterCaseOutline' defaultValue={element.letterCaseOutline} onBlur={handleUpdateField}/>
                                </div>
                            </div>
                            <div className='content-card'>
                                <p className='content-card-label'>Page Background Color</p>
                                <div className='update-field-container'>
                                    <input type='color' className='update-field' id='text pageBg' defaultValue={element.pageBg} onBlur={handleUpdateField}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className='subsection'>
                        <p className='subsection-title'>Page Background Pattern</p>
                        <p className='subsection-subtitle'>Click on the image box and update the background pattern</p>
                        <div className='subsection-content'>
                            <div className='content-card'>
                                <p className='content-card-label'>Pattern</p>
                                {element.pageBgPattern && <p className='remove' id='delete pageBgPattern' onClick={handleDeleteImage}>REMOVE</p>}
                                <div id='with-loader-14' className='update-field-container'>
                                    <input type='file' className='hidden-file-input' id='file-pageBgPattern' onChange={handleUpdateImage}/>
                                    {element.pageBgPattern ?
                                    <img className='update-field image-field' src={element.pageBgPattern} alt='game page background pattern update field' onClick={()=>document.getElementById('file-pageBgPattern').click()}/>
                                    :
                                    <p className='update-field image-field' onClick={()=>document.getElementById('file-pageBgPattern').click()}>&#9656; Select new pattern</p>}
                                    <div id='loader-14' className='loader-container'><Loader /></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='subsection'>
                        <p className='subsection-title'>Sounds {missingData('sounds') ? <span className='missing-data'>Missing data!</span> : ''}</p>
                        <p className='subsection-subtitle'>Choose sounds used in-game</p>
                        <div className='subsection-content'>
                            <div className='content-card'>
                                <p className='content-card-label'>Good Letter Sound</p>
                                <div id='with-loader-15' className='update-field-container'>
                                    <input type='file' className='hidden-file-input' id='file-goodLetterSound' onChange={handleUpdateSound}/>
                                    <p className='update-field audio-field' onClick={()=>document.getElementById('file-goodLetterSound').click()}>&#9656; Select new sound</p>
                                    <div id='loader-15' className='loader-container'><Loader /></div>
                                    {element.goodLetterSound && <audio controls><source src={element.goodLetterSound} />Your browser does not support audio elements</audio>}
                                </div>
                            </div>
                            <div className='content-card'>
                                <p className='content-card-label'>Wrong Letter Sound</p>
                                <div id='with-loader-16' className='update-field-container'>
                                    <input type='file' className='hidden-file-input' id='file-wrongLetterSound' onChange={handleUpdateSound}/>
                                    <p className='update-field audio-field' onClick={()=>document.getElementById('file-wrongLetterSound').click()}>&#9656; Select new sound</p>
                                    <div id='loader-16' className='loader-container'><Loader /></div>
                                    {element.wrongLetterSound && <audio controls><source src={element.wrongLetterSound} />Your browser does not support audio elements</audio>}
                                </div>
                            </div>
                            <div className='content-card'>
                                <p className='content-card-label'>Misplaced Letter Sound</p>
                                <div id='with-loader-17' className='update-field-container'>
                                    <input type='file' className='hidden-file-input' id='file-misplacedLetterSound' onChange={handleUpdateSound}/>
                                    <p className='update-field audio-field' onClick={()=>document.getElementById('file-misplacedLetterSound').click()}>&#9656; Select new sound</p>
                                    <div id='loader-17' className='loader-container'><Loader /></div>
                                    {element.misplacedLetterSound && <audio controls><source src={element.misplacedLetterSound} />Your browser does not support audio elements</audio>}
                                </div>
                            </div>
                            <div className='content-card'>
                                <p className='content-card-label'>Win Sound</p>
                                <div id='with-loader-18' className='update-field-container'>
                                    <input type='file' className='hidden-file-input' id='file-winSound' onChange={handleUpdateSound}/>
                                    <p className='update-field audio-field' onClick={()=>document.getElementById('file-winSound').click()}>&#9656; Select new sound</p>
                                    <div  id='loader-18' className='loader-container'><Loader /></div>
                                    {element.winSound && <audio controls><source src={element.winSound} />Your browser does not support audio elements</audio>}
                                </div>
                            </div>
                            <div className='content-card'>
                                <p className='content-card-label'>Loss Sound</p>
                                <div id='with-loader-19' className='update-field-container'>
                                    <input type='file' className='hidden-file-input' id='file-lossSound' onChange={handleUpdateSound}/>
                                    <p className='update-field audio-field' onClick={()=>document.getElementById('file-lossSound').click()}>&#9656; Select new sound</p>
                                    <div id='loader-19' className='loader-container'><Loader /></div>
                                    {element.lossSound && <audio controls><source src={element.lossSound} />Your browser does not support audio elements</audio>}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='subsection'>
                        <p className='subsection-title'>Published</p>
                        <p className='subsection-subtitle'>A published game will be available on site</p>
                        <div className='subsection-content'>
                            <div className='content-card'>
                                <p className='content-card-label'>Published</p>
                                <div className='update-field-container'>
                                    <div className='radio-field'>
                                        <div className='radio-label-input'>
                                            <label htmlFor='text published yes'>Yes</label>
                                            <input type='radio' id='published yes' name='published' value='yes' checked={element.published === 'yes' ? true: false} onChange={handleUpdatePublished}/>
                                        </div>
                                        <div className='radio-label-input'>
                                            <label htmlFor='text published no'>No</label>
                                            <input type='radio' id='published no' name='published' value='no' checked={element.published === 'no' ? true: false} onChange={handleUpdatePublished}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='button-container'>
                        <button className='delete-button' onClick={removeElement}>Erase Game</button>
                    </div>
                </section>}
            </div>
            }
        </Profile>
    )
}
