import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Game from '../game/Game'
import Modal from '../../utils/modal/Modal'
import GamesPortal from '../gamesPortal/GamesPortal'

import './gamePage.scss'

import motuzLogo from '../../../../images/motuz-logo.png'

const initModal = {
    info: false,
    stats: false,
    settings: false,
    share: false
}

/* Game component */
const gamesParameters = {
    rowsPerBoard: 6,
}

export default function GamePage() {
    // Subdomain
    const [subdomain, setSubdomain] = useState(null)

    //States
    const [settings, setSettings] = useState({keyboard: 'azerty'})
    const [modal, setModal] = useState({stats: false})
    const [volume, setVolume] = useState(true)
    const [colors, setColors] = useState({good: '#D40C88', std: '#0074C2', misplaced: '#FEBF01'})
    const [explainer, setExplainer] = useState('')
    const [subdomainGameData, setSubdomainGameData] = useState({resultStatus: '', guesses: '', games: 0, tries: [], totalTries: 0, losses: 0, avg: 0})
    const {resultStatus, guesses, games, tries, totalTries, losses, avg} = subdomainGameData
    const [updateLS, setUpdateLS] = useState(false)

    // All records
    const [allGamesPlayed, setAllGamesPlayed] = useState([])
    const [selectedRecords, setSelectedRecords] = useState('tous')

    useEffect(() => {
        const host = window.location.host;
        console.log(host)
        const arr = host.split('.')
        console.log(arr[0])
        if (arr.length === 3) {
            setSubdomain(arr[0])
            setSelectedRecords(arr[0])
        }
    }, [])

    useEffect(() => {
        const ls = JSON.parse(window.localStorage.getItem("motuzState"))
        if(ls) {
            let gameLS = null
            if(subdomain) {
                gameLS = ls[subdomain]
                if(gameLS){
                    if(gameLS.current.resultStatus !== '') {
                    setModal({...modal, stats: true})
                    const {games, tries, totalTries, avg, losses, current} = gameLS
                    const {guesses, resultStatus} = current
                    setSubdomainGameData({resultStatus, guesses, games, tries, totalTries, losses, avg})
                    } else {
                        setModal({...modal, stats: false})
                        const {games, tries, totalTries, avg, losses, current} = gameLS
                        const {guesses, resultStatus} = current
                        setSubdomainGameData({resultStatus, guesses, games, tries, totalTries, losses, avg})
                    }
                }
            } else{
                setAllGamesPlayed(Object.entries(ls).map(([key, value]) => {return {subdomain: key, name: value.name}}))
                selectNewRecords(ls)
            }
        }
    }, [subdomain, selectedRecords, updateLS])

    const updateLocalStorage = () => {
        setUpdateLS(!updateLS)
    }

    const changeColors = (gameColors) => {
        setColors(gameColors)
    }

    const changeExplainer = (gameExplainer) => {
        setExplainer(gameExplainer)
    }

    const changeRecords = (e) => {
        setSelectedRecords(e.target.value)
    }

    const selectNewRecords = (ls) => {
        if(selectedRecords !== 'tous') {
            const {name, games, tries, totalTries, avg, losses} = ls[selectedRecords]
                return setSubdomainGameData({name, resultStatus, guesses, games, tries, totalTries, losses, avg})
        } else {
            let tries = Array.from(Array(gamesParameters.rowsPerBoard).keys()).map(el => 0)
            let games = 0
            let totalTries = 0
            let losses = 0
            Object.keys(ls).forEach(subdomain => {
                for(let i=0; i < gamesParameters.rowsPerBoard; i++) {
                    tries[i] += ls[subdomain].tries[i]
                }
                games += ls[subdomain].games
                totalTries += ls[subdomain].totalTries
                losses += ls[subdomain].losses
            })
            return setSubdomainGameData({name: 'Tous', games, tries, totalTries, avg: games > 0 ? Math.round(totalTries/games * 100) / 100 : 0, losses, resultStatus: ''})
        }
    }

    const share = e => {
        const htmlBubble = document.getElementById("bubble")
        htmlBubble.style.display = "none"
        htmlBubble.style.display = "block"
        htmlBubble.style.left = `${e.pageX - 60}px`
        htmlBubble.style.top = `${e.pageY + 20}px`
        htmlBubble.innerHTML = "Copié"
        setTimeout(() => {
            htmlBubble.style.display = "none"
        }, 3000);
        navigator.clipboard.writeText('Clipboard')
    }

    const concatTriesAndLosses = tries.concat([losses])
    const maximumTries = Math.max(...concatTriesAndLosses)
    const heights = concatTriesAndLosses.map(el => `${100 * (el/maximumTries)}%`)

    const navigateToHome = () => {
        window.location.href = `https://motuz.fr`
    }

    return (
        <div className='game-page'>
            <div id="bubble"></div>
            <Modal open={modal.info} close={()=>setModal({...initModal})} fadeIn={true}>
                <div id='modal-instructions' className='modal-container' style={{backgroundColor: '#0E4679'}}>
                    <h1 className='modal-title'>À PROPOS</h1>
                    <section className='hcenter'>
                        {explainer && <p>{explainer}</p>}
                        <div className='instr-row-example'>
                            <div className='letter-container' style={{backgroundColor: colors.std}}><p style={{backgroundColor: colors.good}}>M</p></div>
                            <div className='letter-container' style={{backgroundColor: colors.std}}><p style={{backgroundColor: colors.std}}>O</p></div>
                            <div className='letter-container' style={{backgroundColor: colors.std}}><p style={{backgroundColor: colors.std}}>T</p></div>
                            <div className='letter-container' style={{backgroundColor: colors.std}}><p style={{backgroundColor: colors.std}}>U</p></div>
                            <div className='letter-container' style={{backgroundColor: colors.std}}><p className='misplaced' style={{backgroundColor: colors.misplaced}}>Z</p></div>
                        </div>
                        <p>Vous avez six essais pour deviner le mot du jour, entre 6 et 9 lettres, commun à tous.</p>
                        <p>Vous ne pouvez proposer.</p>
                        <h2 className='modal-title2'>Le saviez-tu</h2>
                        <p>Vous avez six essais pour deviner le mot du jour, entre 6 et 9 lettres, commun à tous.</p>
                        <p>Vous ne pouvez proposer.</p>
                    </section>
                </div>
            </Modal>
            <Modal open={modal.stats} close={()=>setModal({...initModal})} fadeIn={true}>
                <div id='modal-stats' className='modal-container'>
                    {subdomain ? 
                    <div className='game-record'>
                        {resultStatus !== '' && 
                        <div className='game-results'>
                            <h1 className='modal-title'>{resultStatus === 'win' ? 'BRAVO !' : 'BIEN ESSAYÉ !'}</h1>
                            <div className='guesses-container'>
                                <div className='guesses-cards'>
                                    {guesses?.map(({results},idx) => <div key={idx} className='guess-row'>
                                        {results.map((result,idx) => <div key={idx} className='guess-card' style={{backgroundColor: result === 'correct' ? result === 'misplaced' ? colors.misplaced : colors.good : colors.std}}></div>)}
                                    </div>)}
                                </div>
                                <div className='guesses-out-of'>{guesses?.length} / {gamesParameters.rowsPerBoard}</div>
                            </div>
                            <div className='share' onClick={share}>
                                <FontAwesomeIcon icon="fa-solid fa-share-nodes"/>
                                <p> Partager</p>
                            </div>
                        </div>}
                        <div className='game-stats'>
                            <h1 className='modal-title'>Tes Stats</h1>
                            <p>Moyenne: <span className='average'>{avg}</span></p>
                            <p>Parties: {games}</p>
                            <div className='graph'>
                                {tries?.map((count,idx) => {
                                    return (
                                        <div key={idx} className='graph-card'>
                                            <div className='column' style={{height: heights[idx]}}></div>
                                            <p className='try'>{idx + 1}/{gamesParameters.rowsPerBoard}</p>
                                            <p className='count'>{count}</p>
                                        </div>
                                    )
                                })}
                                <div className='graph-card'>
                                    <div className='column' style={{height: heights[heights.length - 1]}}></div>
                                    <p className='try'>X</p>
                                    <p className='count'>{losses}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    :<div className='all-games-records'>
                        <div className='game-stats'>
                            <h1 className='modal-title'>TES STATS</h1>
                            <p>Motuz' joué:</p>
                            <select onChange={changeRecords}>
                                <option value='tous'>Tous</option>
                                {allGamesPlayed?.map(el => <option key={el.subdomain} value={el.subdomain}>{el.name}</option>)}
                            </select>
                            <p>Moyenne: <span className='average'>{avg}</span></p>
                            <p>Parties: {games}</p>
                            <div className='graph'>
                                {tries?.map((count,idx) => {
                                    return (
                                        <div key={idx} className='graph-card'>
                                            <div className='column' style={{height: heights[idx]}}></div>
                                            <p>{idx + 1}/{gamesParameters.rowsPerBoard}</p>
                                            <p className='count'>{count}</p>
                                        </div>
                                    )
                                })}
                                <div className='graph-card'>
                                    <div className='column' style={{height: heights[heights.length - 1]}}></div>
                                    <p className='try'>X</p>
                                    <p className='count'>{losses}</p>
                                </div>
                            </div>
                        </div>
                        <div className='share' onClick={share}>
                            <FontAwesomeIcon icon="fa-solid fa-share-nodes" />
                            <p>Partager</p>
                        </div>
                    </div>}
                </div>
            </Modal>
            <Header />
            {subdomain ?
            <div className='game-container'>
                <Game 
                    subdomain={subdomain}
                    keyboard={settings.keyboard}
                    muted={!volume} 
                    updateLS={updateLocalStorage}
                    changeColors={changeColors}
                    changeExplainer={changeExplainer}
                />
            </div>
            :<GamesPortal />}
            <Footer />
        </div>
    )

    function Header() {
        return (
            <header>
                <div className='header-container'>
                    <div className="header-left">
                        <div className="icon-button" onClick={()=>setModal({...initModal, info: true})}><FontAwesomeIcon icon="fa-solid fa-info" /></div>
                        <div className='icon-button' onClick={()=>setModal({...initModal, stats: true})}><FontAwesomeIcon icon="fa-solid fa-chart-simple" /></div>
                    </div>
                    <div className="header-middle">
                        <div className="motuz-title">
                            <img id='motuz-logo' src={motuzLogo} alt='motuz logo'/>
                            {/*<span className='correct-title'>M</span>
                            <span className='incorrect-title'><img src={emoji} alt='emoji-smiley'/></span>
                            <span className='incorrect-title'>T</span>
                            <span className='incorrect-title'>U</span>
                            <span className='correct-title'><div className=' tilt'>Z</div></span>*/
                            }
                        </div>
                    </div>
                    <div className="header-right">
                        <div className="icon-button" onClick={()=>setVolume(!volume)}>{volume ? <FontAwesomeIcon icon="fa-solid fa-volume-high" /> : <FontAwesomeIcon id="mute" icon="fa-solid fa-volume-xmark" />}</div>
                        <div className="icon-button" onClick={share}><FontAwesomeIcon icon="fa-solid fa-share-nodes" /></div>
                    </div>
                </div>
            </header>
        )
    }
    function Footer() {
        return (
            <footer>
                {subdomain ?
                <p className='pointer' onClick={navigateToHome}><span className='triangle rotated-270'>&#9650;</span>Tous les motuz'</p>
                :<p className='triangle'>&#9650;</p>}
            </footer>
        )
    }
}
